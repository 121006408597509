export default function() {
  /* 表格列头 */
  return [
    {
      title: "病案号",
      dataIndex: "state",
      scopedSlots: { customRender: "state" },
    },
    {
      title: "疾病类型",
      dataIndex: "updateTime",
      // filterMultiple: false,
      // onFilter: (value, record) => record.address.indexOf(value) === 0,
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ["descend", "ascend"],
      // onFilter: (value, record) => record.time.indexOf(value) === 0,
      sorter: (a, b) => a.time.length - b.time.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "科室",
      dataIndex: "memberLeadName",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "病区",
      dataIndex: "name",
    },
    {
      title: "上报账户",
      dataIndex: "age",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: "提交时间",
      dataIndex: "fillRate",
      scopedSlots: { customRender: "fillRate" },
    },
    {
      title: "状态",
      dataIndex: "projectCode",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "操作",
      key: "operation",
      fixed: "right",
      width: 180,
      scopedSlots: { customRender: "operation" },
    },
  ];
}
